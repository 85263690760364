import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BankList from "../components/BankList/bankList"
import "./banks.css"

const NewClientBanks = () => {
  return (
    <Layout>
      <SEO title="Approved Banks" />
      <div>
        <div className="space"></div>
        <div className="bankHeaderCard">
          <label class="bankListHeader">
            SBA-Lenders Who Are Taking New Clients
          </label>
        </div>
        <div className="container primary-bg">
          <section className="container">
            <label className="bankText">
              Check back often as this list is likely to be updated often. Click
              on any of the banks below to be taken to its website.
            </label>
          </section>
          <hr />
          <BankList />
        </div>
      </div>
    </Layout>
  )
}

export default NewClientBanks
