import React, { useEffect } from "react"
import bankList from "../../data/banks"
import "./bankList.css"

const BankList = () => {
  return (
    <main class="main">
      <div>
        {bankList.banks &&
          bankList.banks
            .filter(bank => bank.accepting === "Yes")
            .sort((a,b) => a.Bank> b.Bank ? 1: -1)
            .map((bank, index) => {
              return (
                <div className="bankCard">
                  <a className="bankLink" href={bank.link} target="_blank">
                    {bank.Bank}
                  </a>
                </div>
              )
            })}
      </div>
    </main>
  )
}

export default BankList
